import React, { useState, useContext } from "react"

import { Cross } from "akar-icons"
import PropTypes from "prop-types"

import { checkoutAddPromoCode, checkoutRemovePromoCode } from "../../graphql"
import { CartContext, AuthContext } from "../../store"
import Button from "../common/Button"
import Input from "../common/Input"
import { TYPE_ERROR, toastMessage, TYPE_SUCCESS } from "../common/Toast"

import "./PriceDetails.scss"

const PriceDetails = ({ startPayment, paymentMethod }) => {
  const {
    checkoutId,
    discount,
    voucherCode,
    selectedShippingMethod,
    setSelectedShippingMethod,
  } = useContext(CartContext)
  const { setLoader } = useContext(AuthContext)

  const { shippingPrice, totalPrice } = { ...selectedShippingMethod }

  const shipping = shippingPrice ? shippingPrice.gross.amount : 0
  const [total, setTotal] = useState(totalPrice ? totalPrice.gross.amount : 0)
  const [promoCode, setPromoCode] = useState("")
  const [finalDiscount, setFinalDiscount] = useState(discount)
  const [appliedVoucher, setAppliedVoucher] = useState("")

  const handleClick = () => {
    if (paymentMethod === null) {
      toastMessage("Please select any payment method!", TYPE_ERROR)
      return
    }

    startPayment()
  }
  React.useEffect(() => {
    if (voucherCode && checkoutId) {
      checkoutRemovePromoCode({
        checkoutId,
        promoCode: voucherCode,
      })
    }
    setAppliedVoucher("")
  }, [voucherCode, checkoutId])

  const applyVoucher = async () => {
    setLoader(true)
    setPromoCode("")

    try {
      const res = await checkoutAddPromoCode({ checkoutId, promoCode })

      setLoader(false)

      if (res.checkoutAddPromoCode.checkoutErrors.length === 0) {
        setFinalDiscount(res.checkoutAddPromoCode.checkout.discount.amount)
        setAppliedVoucher(res.checkoutAddPromoCode.checkout.voucherCode)
        setTotal(res.checkoutAddPromoCode.checkout.totalPrice.gross.amount)
        setSelectedShippingMethod({
          ...selectedShippingMethod,
          totalPrice: res.checkoutAddPromoCode.checkout.totalPrice,
        })
        toastMessage("Applied voucher successfully.", TYPE_SUCCESS)
      } else {
        toastMessage(
          res.checkoutAddPromoCode?.checkoutErrors[0].message,
          TYPE_ERROR
        )
      }
    } catch (error) {
      setLoader(false)
      console.log(error)
    }
  }

  const removeVoucher = async () => {
    setLoader(true)

    try {
      const res = await checkoutRemovePromoCode({
        checkoutId,
        promoCode: appliedVoucher,
      })

      setLoader(false)

      if (res.checkoutRemovePromoCode.checkoutErrors.length === 0) {
        setFinalDiscount(res.checkoutRemovePromoCode.checkout.discount.amount)
        setAppliedVoucher(res.checkoutRemovePromoCode.checkout.voucherCode)
        setTotal(res.checkoutRemovePromoCode.checkout.totalPrice.gross.amount)
        setSelectedShippingMethod({
          ...selectedShippingMethod,
          totalPrice: res.checkoutRemovePromoCode.checkout.totalPrice,
        })
        toastMessage("Removed voucher successfully.", TYPE_SUCCESS)
      } else {
        toastMessage(
          res.checkoutRemovePromoCode?.checkoutErrors[0].message,
          TYPE_ERROR
        )
      }
    } catch (error) {
      setLoader(false)
      console.log(error)
    }
  }

  return (
    <div className="price-details">
      <div className="price-details__voucher">
        <label htmlFor="promo-code">
          Do you have a gift card voucher or discount code?
        </label>
        <div className="price-details__voucher__apply">
          <Input
            type="text"
            id="promo-code"
            placeholder="Promo code"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
          />
          <Button onClick={applyVoucher}>Apply</Button>
        </div>
        {appliedVoucher && (
          <div className="price-details__voucher__applied">
            <span>Applied voucher:</span>
            <div>
              <strong>{appliedVoucher}</strong>
              <button onClick={removeVoucher}>
                <Cross size="15" color="#303546" />
              </button>
            </div>
          </div>
        )}
      </div>
      <hr />
      <div className="price-details__price">
        <div className="price-details__price__label">Subtotal:</div>
        <div className="price-details__price__amount">
          ₹ {total - shipping + finalDiscount}
        </div>
      </div>
      {appliedVoucher && (
        <div className="price-details__price">
          <div className="price-details__price__label">Voucher discount:</div>
          <div className="price-details__price__amount">
            - ₹ {finalDiscount}
          </div>
        </div>
      )}
      <div className="price-details__price">
        <div className="price-details__price__label">Shipping:</div>
        <div className="price-details__price__amount">₹ {shipping}</div>
      </div>
      <hr />
      <div className="price-details__price">
        <div className="price-details__price__label">
          <strong>Total:</strong>
        </div>
        <div className="price-details__price__amount">
          <strong>₹ {total}</strong>
        </div>
      </div>
      <Button color="primary" isBlock onClick={handleClick}>
        Pay
      </Button>
    </div>
  )
}

PriceDetails.propTypes = {
  checkoutAndPay: PropTypes.func.isRequired,
  totalPrice: PropTypes.object.isRequired,
  paymentMethod: PropTypes.any.isRequired,
  shippingPrice: PropTypes.object.isRequired,
  startPayment: PropTypes.func.isRequired,
}

export default PriceDetails
