import React, { useContext } from "react"

import { navigate } from "gatsby"

import PropTypes from "prop-types"

import {
  paymentInitialize,
  checkoutPaymentCreate,
  checkoutComplete,
} from "../../graphql"
import { AuthContext, CartContext } from "../../store"
import Loader from "../common/Loader"
import { toastMessage, TYPE_ERROR } from "../common/Toast"
import PaymentMethodCard from "./PaymentMethodCard"
import PriceDetails from "./PriceDetails"

import "./Payment.scss"

const Payment = ({ openModal }) => {
  const [paymentMethod, setPaymentMethod] = React.useState(null)
  const { setLoader } = useContext(AuthContext)
  const [isLoadingRequest, setIsLoadingRequest] = React.useState(false)

  const {
    availablePaymentGateways,
    selectedShippingMethod,
    checkoutId,
    clearCart,
  } = React.useContext(CartContext)
  const { totalPrice } = { ...selectedShippingMethod }

  const generateOrder = () => {
    const data = {
      gateway: paymentMethod.id,
      paymentData: JSON.stringify({
        amount: totalPrice.gross.amount * 100,
        currency: totalPrice.gross.currency,
      }),
    }
    return paymentInitialize(data)
  }

  const completeCheckout = async (paymentConfirmation) => {
    const data = {
      checkoutId,
      gateway: paymentMethod.id,
      token: paymentConfirmation.razorpay_payment_id,
      amount: totalPrice.gross.amount,
    }
    checkoutPaymentCreate(data)
      .then(async (response) => {
        setIsLoadingRequest(true)

        try {
          const res = await checkoutComplete({ checkoutId })
          if (res.checkoutComplete?.checkoutErrors?.length === 0) {
            const orderId = res.checkoutComplete.order.id

            clearCart()
            navigate("/order-success", {
              state: { orderId },
            })
            setIsLoadingRequest(false)
          } else {
            navigate("/")
            setIsLoadingRequest(false)
            toastMessage(
              res.checkoutComplete.checkoutErrors[0].message,
              TYPE_ERROR
            )
          }
        } catch (error) {
          setIsLoadingRequest(false)
          console.log(error)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const startPayment = async () => {
    setLoader(true)
    const orderId = await generateOrder()
    const data = {
      key_id: paymentMethod.config[0].value,
      amount: totalPrice.gross.amount,
      currency: totalPrice.gross.currency,
      order_id: orderId,
      callback: completeCheckout,
    }
    setLoader(false)
    openModal(data)
  }

  return (
    <section className="payment">
      {isLoadingRequest ? (
        <Loader />
      ) : (
        <div className="payment__container">
          <div className="payment__content">
            <div className="payment__content__title">Choose payment method</div>
            <div className="payment__content__methods">
              {availablePaymentGateways.map((method, index) => (
                <PaymentMethodCard
                  key={index}
                  name={method.name}
                  id={method.id}
                  config={method.config}
                  setPaymentMethod={setPaymentMethod}
                  isSelected={paymentMethod?.id === method.id}
                />
              ))}
            </div>
          </div>
          <div className="payment__details">
            <PriceDetails
              paymentMethod={paymentMethod}
              startPayment={startPayment}
            />
          </div>
        </div>
      )}
    </section>
  )
}

Payment.propTypes = {
  openModal: PropTypes.func.isRequired,
}

export default Payment
