import React, { Fragment } from "react"

import { navigate } from "gatsby"

import Payment from "../components/checkout/Payment"
import SEO from "../components/seo"
import { AuthContext, CartContext } from "../store"

const PaymentPage = () => {
  const { user } = React.useContext(AuthContext)
  const { lines } = React.useContext(CartContext)

  React.useEffect(() => {
    if (!localStorage.token) {
      navigate("/login")
    }

    if (lines.length === 0) {
      navigate("/")
    }
  }, [])

  const CALLBACK_URL = "http://localhost:8081/shipping"
  const STORE_NAME = ""
  const TRANSACTION_DESCRIPTION = "Test"
  const LOGO_URL = ""
  const NOTES = "Inkoop Storefront Platform"

  const getRazorpayOptions = (config) => ({
    key: config.key_id,
    amount: config.amount * 100,
    currency: config.currency,
    name: STORE_NAME,
    description: TRANSACTION_DESCRIPTION,
    image: LOGO_URL,
    order_id: config.order_id, // This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    callback_url: CALLBACK_URL,
    prefill: {
      name: user.name,
      email: user.email,
      contact: user.contact,
    },
    notes: {
      address: NOTES,
    },
    handler: function (response) {
      config.callback(response)
    },
    theme: {
      color: "#303546",
    },
  })

  const openModal = (config) => {
    const rzp1 = new window.Razorpay(getRazorpayOptions(config))
    rzp1.open()
  }

  return (
    <Fragment>
      <SEO title="Checkout" path="/checkout">
        <script src="https://checkout.razorpay.com/v1/checkout.js" />
      </SEO>
      <Payment openModal={openModal} />
    </Fragment>
  )
}

export default PaymentPage
