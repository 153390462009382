import React from "react"

import PropTypes from "prop-types"

import "./Loader.scss"

const Loader = ({ size }) => {
  return (
    <div className={`loader ${size === "small" && "loader--small"}`}>
      <div className="loader__container">
        <div className="loader__box1"></div>
        <div className="loader__box2"></div>
        <div className="loader__box3"></div>
        <div className="loader__box4"></div>
        <div className="loader__box5"></div>
      </div>
    </div>
  )
}

Loader.propTypes = {
  size: PropTypes.string,
}

export default Loader
