import React from "react"

import classNames from "classnames"
import PropTypes from "prop-types"

import "./PaymentMethodCard.scss"

const PaymentMethodCard = ({
  name,
  id,
  config,
  isSelected,
  setPaymentMethod,
}) => {
  const paymentClasses = classNames({
    "payment-method": true,
    "payment-method__active": isSelected,
  })

  return (
    <button
      className={paymentClasses}
      onClick={() => setPaymentMethod({ id, config })}
    >
      <h4 className="payment-method__label">{name}</h4>
    </button>
  )
}

PaymentMethodCard.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  setPaymentMethod: PropTypes.func.isRequired,
}

export default PaymentMethodCard
